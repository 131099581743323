import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import loadable from '@loadable/component';

import { changeBlogPagination } from 'common/actions/PageActions';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';
import { getPageDataSelector } from 'common/selectors/page';

import { capfl } from 'common/utils';

const BlogListingVariant = loadable((props) =>
  import(`common/blocks/blog/listing/${props.blockVariant}`)
);

const BlogListing = ({
  t,
  activeCategory,
  articles,
  gotPagination,
  changeBlogPagination,
  blockSettings,
}) => {
  const breadcrumbs = [
    {
      id: 'home',
      name: capfl(t('home')),
      link: {
        route: 'home',
      },
    },
    {
      id: 'blog',
      name: capfl(t('blog')),
      link: {
        route: 'blog',
      },
    },
  ];
  if (activeCategory) {
    breadcrumbs.push({
      id: activeCategory.id,
      name: activeCategory.name,
      urlName: activeCategory.urlName,
      link: {
        href: '/blog?category=' + activeCategory.id,
      },
    });
  }
  const myRef = React.useRef(null);
  return (
    <section ref={myRef}>
      <BlogListingVariant
        blockVariant={_.get(blockSettings, 'blockVariant')}
        breadcrumbs={breadcrumbs}
        data={articles}
        changeBlogPagination={changeBlogPagination}
        gotPagination={gotPagination}
        afterClickRef={myRef}
        t={t}
      />
    </section>
  );
};

const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'BlogListingVariant'
  );
  const mapStateToProps = (state, props) => {
    const pageData = getPageData(state, props);
    let propsToReturn = {
      articles: _.get(pageData, 'articles', null),
      gotPagination: false,
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props),
      },
    };
    if (pageData.activeCategory) {
      propsToReturn.activeCategory = pageData.activeCategory;
    }
    if (pageData.articles) {
      propsToReturn.articles = pageData.articles;
    }
    if (pageData.pagination) {
      propsToReturn.gotPagination = !_.isEmpty(pageData.pagination);
    }
    propsToReturn.changeBlogPagination = changeBlogPagination;
    return propsToReturn;
  };

  return mapStateToProps;
};

const BlogListingConnected = connect(makeMapStateToProps)(BlogListing);

export default withTranslation()(BlogListingConnected);
