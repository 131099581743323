import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import _ from 'lodash';
import Cookies from 'js-cookie';

import { COOKIE_AGE_VERIFIED } from 'common/constants/SiteConstants';

import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import { setToObject } from 'common/utils';

const AgeVerificationModalVariant = loadable(props => import(`./${props.blockVariant}`));

const AgeVerificationModal = ({
  blockSettings = 'AgeVerificationModalVariant0',
  title = '',
  message = ''
}) => {

  const isAccepted = _.toNumber(Cookies.get(COOKIE_AGE_VERIFIED));

  const onAcceptHandler = () => {
    Cookies.set(COOKIE_AGE_VERIFIED, 1);
  }

  if (isAccepted) {
    return null;
  }

  return (
    <AgeVerificationModalVariant
      blockVariant={_.get(blockSettings, 'blockVariant')}
      title={title}
      message={message}
      onAccept={onAcceptHandler}
      isAccepted={isAccepted}
    />
  );
};

const blockSettings = {};
const makeMapStateToProps = () => {
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'AgeVerificationModalVariant'
  );
  return (state, props) => {
    return {
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

export default connect(makeMapStateToProps)(AgeVerificationModal);
